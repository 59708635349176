body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.app-container {
  max-width: 800px;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.app-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.app-title {
  font-size: 2rem;
  color: #1e88e5;
}

.app-description {
  color: #757575;
}

.record-button {
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
  display: block;
  margin: 30px auto;
}

.record-button.recording {
  background-color: #e53935;
}

.record-button:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

.vaccine-list-container {
  margin-top: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.vaccine-list-title {
  font-size: 1.5rem;
  color: #1e88e5;
  margin-bottom: 10px;
}

.vaccine-list-actions {
  text-align: right;
  margin-bottom: 15px;
}

.edit-button,
.save-button,
.extract-csv-button {
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.edit-button:hover,
.save-button:hover,
.extract-csv-button:hover {
  background-color: #1565c0;
}

.vaccine-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vaccine-item-text {
  color: #424242;
}

.edit-vaccine-item {
  display: flex;
  gap: 10px;
}

.edit-vaccine-name,
.edit-vaccine-count {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

.extract-csv-button {
  background-color: #4caf50;
  margin-top: 15px;
}

.extract-csv-button:hover {
  background-color: #388e3c;
}
